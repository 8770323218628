import React from "react";
import { Link } from "react-scroll";
import {BrowserRouter as Router, Route } from "react-router-dom";
// import {HashRouter as Router, Route } from "react-router-dom";

import '../css/App.css';

// App Sections
import SectionHeader from "../components/AppSections/SectionHeader";
import SectionHome from "../components/AppSections/SectionHome";
import SectionTokenomics from "../components/AppSections/SectionTokenomics";
import SectionAboutUs from "../components/AppSections/SectionAboutUs";
import SectionLoader from "../components/AppSections/SectionLoader";
import SectionTokenDistribution from "../components/AppSections/SectionTokenDistribution";
import SectionRoadMap from "../components/AppSections/SectionRoadMap";
import SectionFAQ from "../components/AppSections/SectionFAQ";
import SectionNews from "../components/AppSections/SectionNews";
import SectionFooter from "../components/AppSections/SectionFooter";
import SectionFaucet from '../components/AppSections/SectionFaucet';
import SectionTokenStats from "../components/AppSections/SectionTokenStats";
import ErrorBoundary from "../components/ErrorBoundary";
import Error404 from "../components/404";
import ComingSoon from "../components/AppSections/ComingSoon";

export const MooniverseProps = process.env;

const App = () => {
    return (
        <div className="App" >
            <ErrorBoundary >
                <Router >
                    {/*<Route path="/" component={Error404} />*/}
                    <Route path="/" component={ComingSoon} />

                    {/*<Route path="/" exact={true} component={SectionLoader} />*/}
                    {/*<Route path="/" exact={true} component={SectionHeader} />*/}
                    {/*<Route path="/" exact={true} component={SectionHome} />*/}
                    {/*<Route path="/" exact={true} component={SectionTokenomics} />*/}
                    {/*<Route path="/" exact={true} component={SectionAboutUs} />*/}
                    {/*<Route path="/" exact={true} component={SectionTokenDistribution} />*/}
                    {/*<Route path="/" exact={true} component={SectionRoadMap} />*/}
                    {/*<Route path="/" exact={true} component={SectionFAQ} />*/}
                    {/*<Route path="/" exact={true} component={SectionNews} />*/}
                    {/*<Route path="/" exact={true} component={SectionFaucet} />*/}
                    {/*<Route path="/" exact={true} component={SectionTokenStats} />*/}
                    {/*<Route path="/" exact={true} component={SectionFooter} />*/}
                    {/*<Route path="/404" component={Error404} />*/}
                    {/*<Link activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={150} className="scrollup btn-default nav-item active" >*/}
                    {/*    <i style={{color: '#ffffff'}} className="ion-ios-arrow-up" />*/}
                    {/*</Link >*/}
                </Router >
            </ErrorBoundary >
        </div >
    )
}

export default App;